import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../containers/Layout/Layout';
import HeroSlider from '../components/HeroSlider/HeroSlider';
import SEO from '../components/SEO';
import SectionBox from '../components/SectionBox/SectionBox';
import ProjectFeed from '../components/ProjectFeed/ProjectFeed';
import {
  SectionWrapper,
  RecentWrapper,
  RecentTitle,
} from '../styles/pages/index.css';
import { graphql } from 'gatsby';
import { map } from 'lodash';

const IndexPage = ({ data }) => (
  <Layout>
    <SEO meta={data.allDatoCmsHomepage.nodes[0].seoMetaTags} />
    <HeroSlider
      images={data.allDatoCmsHomepage.nodes[0].homepageSliderImages}
    />
    <SectionWrapper>
      {map(
        data.allDatoCmsHomepage.nodes[0].homepageSections,
        (section, index) => {
          return (
            <SectionBox
              key={index}
              title={section.title}
              body={section.body}
              image={section.image.fluid}
              cta1={{ label: 'Projects', link: section.url }}
              cta2={{ label: 'Inquire', link: '/contact' }}
            />
          );
        }
      )}
    </SectionWrapper>
    <RecentWrapper>
      <RecentTitle>Recent Projects</RecentTitle>
      <ProjectFeed projects={data.allDatoCmsHomepage.nodes[0].recentProjects} />
    </RecentWrapper>
    <RecentWrapper>
      <RecentTitle>About Us</RecentTitle>
      <div
        className="markdown"
        dangerouslySetInnerHTML={{
          __html:
            data.allDatoCmsHomepage.nodes[0].aboutUsNode.childMarkdownRemark
              .html,
        }}
      />
    </RecentWrapper>
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const homeQuery = graphql`
  {
    allDatoCmsHomepage {
      nodes {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        homepageSliderImages {
          id
          fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        homepageSections {
          title
          body
          url
          image {
            id
            fluid(
              maxWidth: 1000
              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        aboutUsNode {
          childMarkdownRemark {
            html
          }
        }
        recentProjects {
          projectTitle
          projectShortDescription
          slug
          projectImages {
            fluid(
              maxWidth: 1000
              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
