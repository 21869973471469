import styled from 'styled-components';
import media from '../MediaQueries';

export const SectionWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  flex-wrap: wrap;
  padding: 60px;
  ${media.tablet`
  padding: 40px;
  `}
  ${media.mobile`
  padding: 20px;
  `}
`;

export const RecentWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 60px;
  ${media.tablet`
  padding: 0 40px;
  `}
  ${media.mobile`
  padding: 0 20px;
  `}

  .markdown {
    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
`;

export const RecentTitle = styled.h4`
  color: black;
  position: relative;
  z-index: 5;
  margin-bottom: 1.5rem;
  font-size: 34px;
  ${media.tablet`
  font-size: 28px;
  `}
  &:after {
    content: '';
    height: 24px;
    background: ${props => props.theme.colors.grey};
    width: 120%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    ${media.mobile`
      width: 100%;
    `}
  }
`;
