import React from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  ImageWrapper,
  Image,
  BodyWrapper,
  Content,
  CTAWrapper,
  CTA,
  Button,
} from './SectionBox.css';

const SectionBox = ({ image, title, body, cta1, cta2 }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image fluid={image} />
      </ImageWrapper>
      <BodyWrapper>
        <Content>
          <h2>{title}</h2>
          <p>{body}</p>
        </Content>
        <CTAWrapper>
          <CTA to={cta1.link}>
            <Button>{cta1.label}</Button>
          </CTA>
          {cta2 && (
            <CTA to={cta2.link}>
              <Button second>{cta2.label}</Button>
            </CTA>
          )}
        </CTAWrapper>
      </BodyWrapper>
    </Wrapper>
  );
};

SectionBox.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  cta1: PropTypes.object.isRequired,
  cta2: PropTypes.object, // Optional second CTA
};

export default SectionBox;
