import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import media from '../../styles/MediaQueries';

export const Wrapper = styled.div`
  background: white;
  width: 100%;
  border: 3px solid black;
  margin-bottom: 1.5em;
  display: flex;
  ${media.tablet`
    flex-wrap: wrap;
  `}
`;

export const ImageWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  border-right: 3px solid black;
  min-height: 400px;
  position: relative;
  ${media.tablet`
    min-height: 300px;
    border-right: none;
    border-bottom: 3px solid black;
  `}
  ${media.mobile`
    min-height: 225px;
  `}
`;

export const Image = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1.5em;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.theme.colors.grey};
  h2 {
    margin-bottom: 1em;
  }
  p {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const CTAWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  border-top: 3px solid black;
`;
export const CTA = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;
  &:hover {
    text-decoration: none;
  }
`;
export const Button = styled.button`
  display: flex;
  width: 100%;
  border: none;
  background: ${props => (props.second ? 'white' : 'black')};
  color: ${props => (props.second ? 'black' : 'white')};
  text-align: center;
  justify-content: center;
  font-weight: 500;
`;
